<template>
    <div class="footer">

    </div>
</template>

<style lang="scss">
.footer{
    margin-top:auto;
}
</style>